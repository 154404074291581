<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{ title }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <div class="col-12 col-xl-8 mb-2 mb-xl-0 d-flex justify-content-between">
                <h4 class="mb-0">{{ title }}</h4>
                <b-button
                  v-if="!isEdit && $permissions.has($permissions.consts.MY_CHANGE_PROFILE)"
                  variant="inverse-warning"
                  :to="{ name: 'Profile', query: { 'mode': 'edit' } }"
                >編輯基本資料
                </b-button>
              </div>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*員工編號"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :value="staff.employee_code"
                    :readonly="true"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*姓名"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.staff.name.$error ? false : null"
                    v-model="staff.name"
                    :readonly="!isEdit"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.staff.name.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  v-if="$permissions.has($permissions.consts.SALES_BIND_URI)"
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="綁定網址"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="salesBindUrl"
                    readonly
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="$permissions.has($permissions.consts.MY_BIND_URI)"
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="綁定網址"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="myBindUrl"
                    readonly
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="個人頭像" label-for="input-1" >
                  <li class="s-image-list-item s-draggable" v-if="image || staff.avatar_url">
                    <img class="s-cropped-image" :src="image || staff.avatar_url">
                    <button v-if="isEdit" class="btn btn-danger s-delete-btn" type="button" @click="deleteImage()">Ｘ</button>
                  </li>
                  <li class="s-image-list-item" v-else>
                    <ImageSelector v-if="isEdit" @input="selectImage">
                      <template #trigger="{ openFileSelector }">
                        <button class="btn s-trigger-btn" @click="openFileSelector">＋ 加入照片</button>
                      </template>
                    </ImageSelector>
                  </li>
                </b-form-group >

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="Email"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.email"
                    :readonly="!isEdit"
                  ></b-form-input>
                </b-form-group
                >

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="手機"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.mobile_phone"
                    :readonly="!isEdit"
                  ></b-form-input>
                </b-form-group
                >

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="職稱"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.job_level"
                    :readonly="!isEdit"
                  ></b-form-input
                  >
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="服務單位"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.service_unit"
                    :readonly="!isEdit"
                  ></b-form-input
                  >
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="部門"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.dept_name"
                    :readonly="!isEdit"
                  ></b-form-input
                  >
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="市話"
                >
                  <div class="d-flex w-100 city-selector-set-has-value">
                    <b-input-group prepend="(" append=")" class="col-3 px-0">
                      <b-form-input
                        class="mb-2 mb-sm-0"
                        type="text"
                        v-model="staff.office_phone_area"
                        :readonly="!isEdit"
                        placeholder="區碼"
                      ></b-form-input>
                    </b-input-group>
                    <b-input-group append="#" class="col-8 px-0">
                      <b-form-input
                        class="mb-2 mb-sm-0"
                        type="text"
                        v-model="staff.office_phone"
                        :readonly="!isEdit"
                        placeholder="市話"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-input
                      class="mb-2 mb-sm-0 col-1"
                      type="text"
                      v-model="staff.office_phone_ext"
                      :readonly="!isEdit"
                      placeholder="分機"
                    ></b-form-input>
                  </div>
                  <b-form-invalid-feedback :state="!v$.staff.office_phone_area.$error && !v$.staff.office_phone.$error && !v$.staff.office_phone_ext.$error ">
                    此欄位為必須為數字
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="Line 連結網址"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.line_contact_url"
                    :readonly="!isEdit"
                  ></b-form-input
                  >
                </b-form-group>
              </div>
            </div>
            <div
              class="d-flex justify-content-center"
              style="margin-top: 100px"
            >
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
              >返回
              </b-button>
              <b-button v-if="isEdit" @click="handleSubmit" variant="success">儲存</b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required, numeric} from "@vuelidate/validators";
import meApi from "@/apis/me";
import store from "@/store";
import imageApi from "@/apis/image";
import ImageSelector from '@/components/ImageSelector'

export default {
  setup: () => ({v$: useVuelidate()}),
  components: { ImageSelector },

  validations() {
    return {
      staff: {
        name: {required},
        office_phone_area: {numeric},
        office_phone: {numeric},
        office_phone_ext: {numeric},
      },
    };
  },

  data() {
    return {
      showLoading: true,
      validationErrors: null,
      staff: {
        name: null,
        mobile_phone: null,
        email: null,
        employee_code: null,
        service_unit: null,
        job_level: null,
        dept_name: null,
        line_contact_url: null,
        avatar_url: null,
        office_phone_area: null,
        office_phone: null,
        office_phone_ext: null,
      },
      image: null,
    };
  },

  computed: {
    isEdit() {
      return this.$route.query.mode == 'edit'
    },
    title() {
      return this.isEdit ? '編輯基本資料' : '檢視基本資料'
    },
    salesBindUrl() {
      const org = this.$store.state.general.organization
      if (! org.short_code) {
        return '尚未設定短網址'
      }
      return `${process.env.VUE_APP_SHORT_URL_HOST}/${org.short_code}/s/${this.staff.id}`
    },
    myBindUrl() {
      let url = process.env.VUE_APP_BIND_URL_HOST || 'https://qa.fyr.at/aa/'
      return url + this.staff.id + '&inline=1'
    }
  },

  mounted() {
    this.fetchProfile();
  },

  methods: {
    async fetchProfile() {
      const response = await meApi.getProfile();
      this.staff = response.data.data;
      this.showLoading = false;
    },
    selectImage(image) {
      this.image = image;
    },
    deleteImage() {
      this.image = null;
      this.staff.avatar_url = null;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      if (this.image) {
        const resp = await this.uploadImage();
        if (! (resp.status && resp.status === 200)) {
          return;
        }
      }

      try {
        let response = await meApi.updateProfile(this.staff);

        if (response.status && response.status === 200) {
          this.$swal.fire({
            title: "更新成功",
            type: "success",
          });
          await store.dispatch('auth/fetchMe');
          this.$router.push({name: "Profile"});
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: "更新失敗",
            type: "error",
          });
        }
      }
    },
    async uploadImage() {
      try {
        let response = await imageApi.uploadByBase64(this.image);
        this.staff.avatar_url = response.data.data.image.url
        return response
      } catch (error) {
        this.$swal.fire({
          title: `上傳圖片失敗：${error.response.data.message}`,
          type: "error",
        });
      }
    },
    handleCancel() {
      this.isEdit ? this.$router.push({name: "Profile"}) : this.$router.push({name: "DashboardHome"});
    },
  },
};
</script>

<style scoped>
  .s-image-list {
    margin: -0.75rem;
    display: flex;
    flex-wrap: wrap;
  }

  .s-image-list-item {
    position: relative;
    width: 7rem;
    height: 7rem;
    border: 1px solid #ddd;
    margin: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .s-cropped-image {
    width: 100%;
    height: 100%;
  }

  .s-delete-btn {
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .s-image-selector {
    width: 100%;
    height: 100%;
  }

  .s-trigger-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
</style>
